import React, { useId, useState } from 'react';

import { CFColorTokenProp } from '../../../theme/tokens/contentful-color';

import { WidgetProps, DEFAULT_BUTTON_TEXT, SharedProps } from '../shared';
import { Widget, ErrorMessage } from '../styles';
import cx from 'classnames';

import {
  MobilePhoneNumberInput,
  MobilePhoneNumberLabel,
  MobilePhoneNumberButton
} from './MobilePhoneNumberWidget.styles';
import { usePageDataContext } from '~/context/PageData';

interface MobilePhoneNumberWidgetProps extends WidgetProps, SharedProps {
  useDropshadow?: boolean;
  responsive?: boolean;
}

export function MobilePhoneNumberWidget({
  autocompleteAttribute,
  buttonColor = 'ginger',
  buttonHoverColor = 'green',
  borderColor = '#3BBFAD',
  buttonText = DEFAULT_BUTTON_TEXT,
  returningUserButtonText,
  buttonContent,
  label,
  errorMessage,
  errorPosition,
  isReturning,
  showErrorBanner,
  inputProps,
  segmentEventName,
  submitted,
  successMessage = 'Thank You!',
  useDropshadow = false,
  responsive
}: MobilePhoneNumberWidgetProps) {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputId = useId();
  const showSuccessMessage = () => {
    return (!submitted && !!segmentEventName) || !segmentEventName;
  };
  const { cookies: { userCTAInteraction } = {} } = usePageDataContext();
  const interactedWithCTA =
    userCTAInteraction && userCTAInteraction?.includes(segmentEventName || '');

  const { id, value } = inputProps ?? {};

  return (
    <div
      className={cx(
        'relative z-[3] flex h-full max-h-16 flex-row items-center justify-center',
        {
          'w-fit': !responsive
        }
      )}
    >
      <Widget alignItems="center">
        <MobilePhoneNumberLabel
          htmlFor={id ?? inputId}
          floatLabel={!!value || isFocused}
        >
          {showSuccessMessage() ? label : ''}
        </MobilePhoneNumberLabel>
        <MobilePhoneNumberInput
          {...inputProps}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          id={id ?? inputId}
          placeholder=""
          autoComplete={autocompleteAttribute ?? 'tel'}
          borderColor={borderColor}
          floatLabel={!!value || isFocused}
          submitted={submitted && !!segmentEventName}
          value={submitted && !!segmentEventName ? successMessage : value}
          data-e2e="phone-number"
          data-testid="mobile-phone-number-input"
          useDropshadow={useDropshadow}
          isReturning={isReturning}
          responsive={responsive}
        />
        {showSuccessMessage() && (
          <MobilePhoneNumberButton
            type="submit"
            cfButtonColor={buttonColor as CFColorTokenProp}
            cfButtonHoverColor={buttonHoverColor as CFColorTokenProp}
            useDropshadow={useDropshadow}
            data-e2e="btn-getting-started"
            data-testid="btn-getting-started"
          >
            {(!segmentEventName && isReturning) || interactedWithCTA
              ? returningUserButtonText ?? (buttonContent || buttonText)
              : buttonContent || buttonText}
          </MobilePhoneNumberButton>
        )}
      </Widget>

      {showErrorBanner && errorMessage && (
        <ErrorMessage position={errorPosition}>{errorMessage}</ErrorMessage>
      )}
    </div>
  );
}
